<template>
  <div>
    <LazyNavbar />
    <slot />
    <LazyFooter />
    <div class="whatsapp-button slide-top">
      <NuxtLink
        to="https://api.whatsapp.com/send/?phone=5511978373432&text&type=phone_number&app_absent=0"
        style="height: 100%"
      >
        <img format="webp" src="/public/whatsapp.webp" alt="whatsapp" loading="lazy" :quality="100" sizes="xs:50px lg:50px" />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
const img = useImage()

const ogTagImage = computed(() => {
  const imgUrl = img('https://sis-it.com/logo-share-1200x1200-v1.png', {
    sizes: 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw',
    format: 'png',
    quality: 100,
    height: 500,
    width: 500,
  })
  return imgUrl
})

useSeoMeta({
  title: 'SIS Innov & Tech - A melhor estratégia para a sua empresa',
  description: `A SIS Innov & Tech é uma consultoria estratégica de Inovação e Transformação Digital que fornece o que há de mais tecnológico para os negócios, com alta performance e comprometimento.`,
  ogTitle: 'SIS Innov & Tech',
  ogLocale: 'pt_BR',
  ogType: 'website',
  ogDescription:
    'A SIS Innov & Tech é uma consultoria estratégica de Inovação e Transformação Digital que fornece o que há de mais tecnológico para os negócios, com alta performance e comprometimento.',
  ogUrl: 'https://sis-it.com',
  ogImage: ogTagImage,
})

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://sis-it.com' + route.path,
    },
  ],
})
</script>

<style scoped lang="scss">
.whatsapp-button {
  position: fixed;
  right: 20px;
  bottom: 0;
  padding: 10px;
  z-index: 99;
  opacity: 0.5;

  img {
    height: 50px;
    filter: drop-shadow(1px 1px 5px #6363632f);
  }
}

.whatsapp-button:hover {
  opacity: 1;
  cursor: pointer;
  img {
    filter: drop-shadow(5px 5px 10px #41414191);
    transition: filter 0.3s ease-in-out;
  }
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>
